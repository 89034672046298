import { createContext } from 'react';
import { getter } from './actions';
import { Authorization, CookieSetter } from './types';

const context = createContext<[Authorization, CookieSetter]>([
  getter(),
  () => null,
]);

export default context;
