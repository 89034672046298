/* eslint-disable @typescript-eslint/no-explicit-any */
export type CookieSetter = (authorization?: Authorization) => void;

export enum ActionType {
  SET,
}

export interface Action {
  type: ActionType;
  [name: string]: any;
}

export interface Authorization {
  access_token?: string;
  token_refresh?: string;
  type?: 'bearer';
}
