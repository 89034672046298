import { Authorization, Action, ActionType } from './types';

export default function reducer(
  state: Authorization,
  action: Action,
): Authorization {
  switch (action.type) {
    case ActionType.SET:
      return { ...action.payload };
    default:
      return state;
  }
}
