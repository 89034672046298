import Cookie from 'js-cookie';
import { Authorization, Action, ActionType } from './types';

interface Request {
  cookies: {
    [key: string]: string;
  };
}

export const ReqSSR: { current: Request | null } = {
  current: null,
};

export function getter(): Authorization {
  const cookie =
    ReqSSR.current?.cookies?.['wgt-authorization'] ??
    Cookie.get('wgt-authorization');

  if (cookie) {
    return JSON.parse(cookie);
  }

  return {};
}

export function setter(authorization?: Authorization): Action {
  const cookieDomain =
    process.env.RAZZLE_APP_COOKIE_DOMAIN ??
    process.env.REACT_APP_COOKIE_DOMAIN ??
    'localhost';
  if (authorization) {
    Cookie.set('wgt-authorization', JSON.stringify(authorization), {
      domain: cookieDomain,
      expires: 30,
    });
  } else {
    Cookie.remove('wgt-authorization', {
      domain: cookieDomain,
    });
  }
  return {
    type: ActionType.SET,
    payload: authorization,
  };
}
