import React, { useEffect, useReducer, Dispatch } from 'react';

import authenticationContext from './AuthenticationContext';
import { getter, setter } from './actions';
import { Action, Authorization } from './types';
import reducer from './reducer';

export const AuthenticationActions: {
  dispatch?: Dispatch<Action>;
  getter: () => Authorization;
} = {
  getter,
  dispatch: undefined,
};

export default function AuthenticationProvider({
  children,
}: {
  children: JSX.Element;
}): JSX.Element {
  const [{ access_token, token_refresh, type }, dispatch] = useReducer(
    reducer,
    getter(),
  );

  const set = (authorization?: Authorization) => [
    dispatch(setter(authorization)),
  ];

  useEffect(() => {
    AuthenticationActions.dispatch = dispatch;

    return () => {
      AuthenticationActions.dispatch = undefined;
    };
  }, []);

  return (
    <authenticationContext.Provider
      value={[{ access_token, token_refresh, type }, set]}
    >
      {children}
    </authenticationContext.Provider>
  );
}
